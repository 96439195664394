/** @jsx jsx */
import { jsx, Flex, Box, Styled } from "theme-ui"
import Container from "./container"
import Spacer from "../components/spacer"

const Icon = props => (
  <Box
    as="svg"
    children={props.children}
    sx={{ display: "block", width: 32, height: 32 }}
    {...props}
  />
)

const LinkedInIcon = props => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <g fill="none" fillRule="evenodd">
      <rect fill="#202020" width="32" height="32" rx="16" />
      <g transform="translate(8 7)" fill="#FFF" fillRule="nonzero">
        <rect y="6.225" width="4.15" height="11.62" rx=".415" />
        <path d="M13.695 17.845h2.49c.23 0 .415-.186.415-.415v-6.972c0-3.13-1.768-4.648-4.241-4.648a3.494 3.494 0 00-2.631 1.054.34.34 0 01-.598-.224.415.415 0 00-.415-.415h-2.49a.415.415 0 00-.415.415v10.79c0 .23.186.415.415.415h2.49c.23 0 .415-.186.415-.415v-6.225a2.075 2.075 0 114.15 0v6.225c0 .23.186.415.415.415z" />
        <ellipse cx="2.058" cy="2.49" rx="2.058" ry="2.075" />
      </g>
    </g>
  </Icon>
)

const FooterLink = props => (
  <Styled.a
    sx={{ ml: 2, color: "text" }}
    children={props.children}
    {...props}
  />
)
export default props => (
  <Container as="footer">
    <Spacer size={[4, null, 5]} />
    <Flex
      sx={{
        flexDirection: ["column", "row"],
        justifyContent: ["space-between"],
        alignItems: "center",
      }}
    >
      <Box sx={{ order: [1, 0], mt: [3, 0] }}>
        &copy; {new Date().getFullYear()} Bradleys Digital
      </Box>
      <Flex
        sx={{
          flexDirection: ["column", "row"],
          alignItems: "center",
          order: [0, 1],
          ml: [-2, 0],
        }}
      >
        <Flex sx={{ ml: [0, 2], order: [0, 1] }}>
          <FooterLink href="https://www.linkedin.com/company/bradleysdigital/about/">
            <LinkedInIcon />
          </FooterLink>
        </Flex>
      </Flex>
    </Flex>
    <Spacer size={[4, null, 5]} />
  </Container>
)

/** @jsx jsx */
import { jsx, Flex, Box } from "theme-ui"
import { useRef } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import Logo from "./logo"
import Navigation from "./navigation"
import MobileNavigation from "./mobileNavigation"
import { MenuToggle } from "./menuToggle"
import { useDimensions } from "../hooks/useDimensions"
import { motion, useCycle } from "framer-motion"
import Headroom from "react-headroom"

const MotionBox = motion.custom(Box)

const Header = ({ siteTitle, locale }) => {
  const data = useStaticQuery(graphql`
    query NavigationQuery {
      allContentfulNavigation(filter: { title: { eq: "Main Navigation" } }) {
        edges {
          node {
            node_locale
            navigationElements {
              id
              title
              node_locale
              page {
                slug
              }
              path
              subNavigationElements {
                title
                page {
                  slug
                }
                path
              }
            }
          }
        }
      }
    }
  `)

  const navigation = data.allContentfulNavigation.edges.filter(
    ({ node }) => node.node_locale === locale
  )[0].node.navigationElements
  const [isOpen, toggleOpen] = useCycle(false, true)
  const containerRef = useRef(null)

  return (
    <Headroom
      sx={{
        minHeight: isOpen ? "calc(var(--vh, 1vh) * 100)" : "auto",
        ".headroom": {
          position: "fixed",
          height: isOpen ? "calc(var(--vh, 1vh) * 100)" : "auto",
        },
        ".headroom--scrolled": {
          background: `
            linear-gradient(
              to bottom,
              hsl(0, 0%, 100%) 0%,
              hsla(0, 0%, 100%, 0.987) 14%,
              hsla(0, 0%, 100%, 0.951) 26.1%,
              hsla(0, 0%, 100%, 0.896) 36.6%,
              hsla(0, 0%, 100%, 0.825) 45.6%,
              hsla(0, 0%, 100%, 0.741) 53.3%,
              hsla(0, 0%, 100%, 0.648) 59.8%,
              hsla(0, 0%, 100%, 0.55) 65.5%,
              hsla(0, 0%, 100%, 0.45) 70.4%,
              hsla(0, 0%, 100%, 0.352) 74.7%,
              hsla(0, 0%, 100%, 0.259) 78.7%,
              hsla(0, 0%, 100%, 0.175) 82.6%,
              hsla(0, 0%, 100%, 0.104) 86.4%,
              hsla(0, 0%, 100%, 0.049) 90.5%,
              hsla(0, 0%, 100%, 0.013) 95%,
              hsla(0, 0%, 100%, 0) 100%
            )`,
          "> header": { py: [4] },
        },
      }}
    >
      <MotionBox
        as="header"
        initial={false}
        animate={isOpen ? "open" : "closed"}
        ref={containerRef}
        sx={{
          display: "flex",
          //position: isOpen ? ["fixed", null, null, "relative"] : "relative",
          zIndex: 2,
          justifyContent: "space-between",
          width: "100%",
          minHeight: isOpen ? "calc(var(--vh, 1vh) * 100)" : "auto",
          p: [4, null, 5],
        }}
      >
        <Flex
          sx={{
            flex: "none",
            alignItems: "flex-start",
            justifyContent: "space-between",
            width: ["100%", null, null, "auto"],
          }}
        >
          <Link
            to={locale === "fi" ? "/" : `/${locale}/`}
            title={siteTitle}
            sx={{
              display: "block",
              flex: "none",
              zIndex: 2,
            }}
          >
            <Logo />
          </Link>
          <MenuToggle toggle={() => toggleOpen()} />
        </Flex>
        <Navigation navigation={navigation} locale={locale} />
        <MobileNavigation
          navigation={navigation}
          isOpen={isOpen}
          locale={locale}
        />
      </MotionBox>
    </Headroom>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

/** @jsx jsx */
import { jsx, Styled, Box, Flex } from "theme-ui"
import { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { MenuItem } from "./menuItem"
import { Link } from "gatsby"

export default props => {
  const [expanded, setExpanded] = useState(false)
  const navigation = props.navigation
  const locale = props.locale

  const variants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
      backgroundColor: "rgba(220, 234, 241, 1)",
      visibility: "visible",
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
      backgroundColor: "rgba(220, 234, 241, 0)",
      visibility: "hidden",
    },
  }

  return (
    <motion.div
      variants={variants}
      sx={{
        position: ["fixed", null, null, "static"],
        top: 0,
        right: 0,
        left: 0,
        zIndex: 1,
        height: "calc(var(--vh, 1vh) * 100)",
        display: ["flex", null, null, "none"],
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
        p: [4, null, 5],
      }}
    >
      <Flex
        sx={{
          mt: 3,
          flex: "auto",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {navigation &&
          navigation.map((item, key) => (
            <MenuItem
              as={item.page || item.path ? Link : "div"}
              to={
                item.page || item.path
                  ? locale === "en"
                    ? `/en/${item.page ? item.page.slug : item.path}`
                    : `/${item.page ? item.page.slug : item.path}`
                  : ""
              }
              key={key}
              expanded={expanded}
              subNavigation={
                item.subNavigationElements && item.subNavigationElements.length
              }
              onClick={() =>
                item.subNavigationElements &&
                setExpanded(expanded ? false : true)
              }
            >
              {item.title}
              {item.subNavigationElements && (
                <AnimatePresence initial={false}>
                  {expanded && (
                    <motion.section
                      key="content"
                      initial="collapsed"
                      animate="open"
                      exit="collapsed"
                      variants={{
                        open: { opacity: 1, height: "auto" },
                        collapsed: { opacity: 0, height: 0 },
                      }}
                      transition={{ duration: 0.15, ease: [0.04, 0.6, 0.2, 1] }}
                    >
                      <motion.div
                        variants={{
                          collapsed: { scale: 0.9 },
                          open: { scale: 1 },
                        }}
                        transition={{ duration: 0.15 }}
                      >
                        <Box sx={{ fontSize: 3, pb: 2 }}>
                          {item.subNavigationElements.map((item, i) => (
                            <Styled.a
                              as={Link}
                              to={
                                locale === "en"
                                  ? `/en/${
                                      item.page ? item.page.slug : item.path
                                    }`
                                  : `/${item.page ? item.page.slug : item.path}`
                              }
                              sx={{
                                display: "block",
                                mb: 1,
                                ml: 2,
                                color: "text",
                              }}
                              key={i}
                            >
                              {item.title}
                            </Styled.a>
                          ))}
                        </Box>
                      </motion.div>
                    </motion.section>
                  )}
                </AnimatePresence>
              )}
            </MenuItem>
          ))}
      </Flex>
      <Flex sx={{ flex: "none", justifySelf: "flex-end", fontSize: 3, mx: -2 }}>
        <Styled.a
          as={Link}
          to={props.locale === "en" ? "/" : "/en"}
          sx={{ display: "block", px: 2, color: "text" }}
        >
          {props.locale === "en" ? "Suomeksi" : "In English"}
        </Styled.a>
      </Flex>
    </motion.div>
  )
}

/** @jsx jsx */
import { jsx, Box } from "theme-ui"

export default props => (
  <Box
    sx={{ width: "100%", maxWidth: 1440, mx: "auto", px: [4, null, 5] }}
    {...props}
  >
    {props.children}
  </Box>
)

/** @jsx jsx */
import { jsx, Box, Styled } from "theme-ui"
import { Link } from "gatsby"

const Menu = ({ children }) => (
  <Box
    sx={{
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      bg: "rgba(255,255,255,.9)",
      visibility: "hidden",
      opacity: 0,
      top: "75%",
      boxShadow: `0 2.8px 2.2px rgba(0, 0, 0, 0.02),
                    0 6.7px 5.3px rgba(0, 0, 0, 0.028),
                    0 12.5px 10px rgba(0, 0, 0, 0.035),
                    0 22.3px 17.9px rgba(0, 0, 0, 0.042),
                    0 41.8px 33.4px rgba(0, 0, 0, 0.05),
                    0 100px 80px rgba(0, 0, 0, 0.07)`,
      px: 24,
      py: 2,
      borderRadius: 4,
      transition: "all .15s ease",
      "::before": {
        position: "absolute",
        top: -16,
        left: "50%",
        content: "''",
        zIndex: 1,
        ml: "-8px",
        border: "8px solid transparent",
        borderBottomColor: "rgba(255,255,255,.9)",
      },
    }}
    children={children}
  />
)

const NavLink = props => (
  <Styled.a
    sx={{
      position: "relative",
      display: "block",
      color: "text",
      whiteSpace: "nowrap",
      fontSize: 3,
      cursor: "pointer",
      "&[aria-current=page]": { color: "primaryDark" },
      ":hover": {
        "> div": { opacity: 1, visibility: "visible", top: "100%" },
      },
    }}
    {...props}
  >
    {props.children}
  </Styled.a>
)

const MenuLink = props => (
  <Styled.a
    as={Link}
    sx={{
      display: "block",
      py: 1,
      color: "text",
      hover: {
        color: "primaryDark",
      },
    }}
    {...props}
  >
    {props.children}
  </Styled.a>
)

export default props => {
  const navigation = props.navigation
  const locale = props.locale

  return (
    <Box
      sx={{
        display: ["none", null, null, "grid"],
        gridTemplateColumns: "auto",
        gridAutoFlow: "column",
        gridGap: 4,
      }}
    >
      {navigation &&
        navigation.map((item, key) => (
          <NavLink
            as={item.page || item.path ? Link : "div"}
            to={
              item.page || item.path
                ? locale === "en"
                  ? `/en/${item.page ? item.page.slug : item.path}`
                  : `/${item.page ? item.page.slug : item.path}`
                : ""
            }
            key={key}
            {...props}
          >
            {item.title}
            {item.subNavigationElements && (
              <Menu>
                {item.subNavigationElements.map((item, i) => (
                  <MenuLink
                    to={
                      locale === "en"
                        ? `/en/${item.page ? item.page.slug : item.path}`
                        : `/${item.page ? item.page.slug : item.path}`
                    }
                    key={i}
                  >
                    {item.title}
                  </MenuLink>
                ))}
              </Menu>
            )}
          </NavLink>
        ))}
      <NavLink to={locale === "en" ? "/en" : "/"}>
        {locale === "en" ? "EN" : "FI"}
        <Menu>
          <MenuLink to="/">FI</MenuLink>
          <MenuLink to="/en">EN</MenuLink>
        </Menu>
      </NavLink>
    </Box>
  )
}

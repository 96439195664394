/** @jsx jsx */
import { jsx, Styled, Flex, Box } from "theme-ui"
import { motion } from "framer-motion"
import { Link } from "gatsby"

const MotionFlex = motion.custom(Flex)
const MotionBox = motion.custom(Box)

export const MenuItem = props => {
  const variants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  }

  return (
    <MotionFlex variants={variants} sx={{ justifyContent: "space-between" }}>
      <Styled.a
        as={props.to ? Link : "a"}
        sx={{
          color: "text",
          fontSize: [5, null, null, 3],
          cursor: "pointer",
          "&[aria-current=page]": { color: "primaryDark" },
        }}
        {...props}
      >
        {props.children}
      </Styled.a>
      {props.subNavigation && (
        <MotionBox
          sx={{
            transformOrigin: "center center",
            display: "flex",
            justifyContent: "center",
            flex: "none",
            width: 32,
            height: 32,
            mt: 2,
          }}
          onClick={props.onClick}
          animate={{
            transform: props.expanded ? "rotate(45deg)" : "rotate(90deg)",
          }}
        >
          <Box
            as="svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            sx={{ width: 32, height: 32 }}
          >
            <path
              fillRule="evenodd"
              d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
              clipRule="evenodd"
            />
          </Box>
        </MotionBox>
      )}
    </MotionFlex>
  )
}

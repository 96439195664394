/** @jsx jsx */
import { Styled, Main, Layout as ThemeLayout, jsx } from "theme-ui"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Global } from "@emotion/core"
import Helmet from "react-helmet"

import Header from "./header"
import Footer from "./footer"
import "../fonts/bradleys.css"

const Layout = ({ children, ...props }) => {
  const locale = props.locale ? props.locale : "fi"
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Styled.root>
      <Global
        styles={{
          "*": {
            boxSizing: "border-box",
          },
          body: {
            margin: 0,
            WebkitFontSmoothing: "antialiased",
            MozOsxFontSmoothing: "grayscale",
          },
        }}
      />
      <Helmet>
        <script
          type="text/javascript"
          src="https://secure.venture-enterprising.com/js/788309.js"
        ></script>
        <noscript>
          {`
          <img
            alt=""
            src="https://secure.venture-enterprising.com/788309.png"
            style="display:none;"
          />
        `}
        </noscript>
      </Helmet>
      <ThemeLayout>
        <Header siteTitle={data.site.siteMetadata.title} locale={locale} />
        <Main sx={{ mt: [-100, -119, -183] }}>{children}</Main>
        <Footer hideContactCta={props.hideContactCta} locale={locale} />
      </ThemeLayout>
    </Styled.root>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
